
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmTabs from '@/components/shared/TmTabs.vue'

export default defineComponent({
  name: 'ServicesAutomationRules',
  components: {
    TmTabs,
    PageContent,
  },
  setup() {
    const currentRoute = ref('')
    const tabs = [
      { name: 'Rules', to: { name: 'base.services.automationRules.rules' } },
      { name: 'Keywords', to: { name: 'base.services.automationRules.keywords' } },
      { name: 'Logs', to: { name: 'base.services.automationRules.logs' } },
    ]

    return {
      currentRoute,
      tabs,
    }
  },
})
